import { render, staticRenderFns } from "./NewSub.vue?vue&type=template&id=1376d364&"
import script from "./NewSub.vue?vue&type=script&lang=js&"
export * from "./NewSub.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/builds/rentmagic/rentmagic.website.vue.west-vlaanderen/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormInput, BCol, BFormGroup, BFormText, BButton, BInputGroupAppend, BInputGroup, BRow, BCard, BAlert, BForm, BContainer} from 'bootstrap-vue'
    installComponents(component, {BFormInput, BCol, BFormGroup, BFormText, BButton, BInputGroupAppend, BInputGroup, BRow, BCard, BAlert, BForm, BContainer})
    

export default component.exports